'use strict';

/*eslint no-console:0*/

var tm  = require('text-maze');
var tmr = require('text-maze-render');

var maze = null;

var smallScreenMax   = 767;
var lastScreenWidth  = null;
var lastScreenHeight = null;

var showText     = true;
var showSolution = true;
var renderer     = 'bordered';

var textCustom       = null;
var textInput        = document.querySelector('.maze-text-input');
var textInputTimeout = null;

var canvas = document.querySelector('.text-maze');

document.querySelector('.maze-text-input').oninput = delay.bind(null, 500);
document.querySelector('.toggle-text').onclick     = toggleText;
document.querySelector('.toggle-solution').onclick = toggleSolution;
document.querySelector('.toggle-style').onclick    = toggleStyle;
document.querySelector('.recreate').onclick        = recreate;
document.querySelector('.reset').onclick           = reset;

window.onresize = delay.bind(null, 100);

module.exports = function() {
    build(true);
};

function getDefaultText() {
    var smallScreen = '\'Still round the corner there may wait, a new road or a secret gate.\' JRR Tolkien';
    var largeScreen = '\'Still round the corner there may wait, a new road or a secret gate.\' JRR Tolkien';
    // var largeScreen = '\'They were a scratch company of barbarians and slaves and poor and unimportant people ... they moved together and were very absolute about who and what was really a part of their little system.\' gk chesterton';

    if (window.innerWidth <= smallScreenMax) {
        textInput.placeholder = 'Customize maze text';
        return smallScreen;
    }

    textInput.placeholder = 'What do you want the maze to say?';
    return largeScreen;
}

function build(doRender) {
    textCustom = textInput.value;
    lastScreenWidth = window.innerWidth;
    lastScreenHeight = window.innerHeight;

    maze = tm.init(textCustom || getDefaultText());

    if (doRender) render();
}

function render() {
    tmr[renderer](maze.maze, {
        cellSize: maze.cellSize,
        endpoints: maze.endpoints,
        showText: showText,
        showSolution: showSolution
    });

    // Size input to width of maze.
    textInput.style.width = canvas.width + 'px';
}

// Delay render while changing input text.
function delay(ms) {
    clearTimeout(textInputTimeout);
    textInputTimeout = setTimeout(build.bind(null, true), ms);
}

function toggleText() {
    showText = ! showText;
    render();
}

function toggleSolution() {
    showSolution = ! showSolution;
    render();
}

function toggleStyle() {
    switch (renderer) {
    case 'bordered':
        renderer = 'walled';
        break;
    case 'walled':
        renderer = 'blocky';
        break;
    case 'blocky':
        renderer = 'bordered';
    }

    render();
}

function recreate() {
    lastScreenWidth = -1; // Hack to force rebuild - the last screen width will be set to what it was anyway.

    build(true);
}

function reset() {
    if (textInput.value != '') {
        textInput.value = '';
        showText        = true;
        showSolution    = true;
        renderer        = 'bordered';

        build(true);
    }
    else if (showText != true || showSolution != true || renderer != 'bordered') {
        showText     = true;
        showSolution = true;
        renderer     = 'bordered';

        render();
    }
}
