'use strict';

/*eslint no-console:0*/

var textmaze = require('./maze');

var firstRoute = null;

var routes = window.routes = {

    '/': {
        page: document.querySelector('#home-page'),
        render: function() {
            updateRoute('/');
            showPage('/');
        }
    },

    '/maze': {
        page: document.querySelector('#maze-page'),
        render: function() {
            updateRoute('/maze');
            showPage('/maze');
            this.run();
            return false;
        },
        run: function() {
            textmaze();
        }
    },

    '/404': {
        page: document.querySelector('#error-page'),
        render: function() {
            updateRoute('/404');
            showPage('/404');
        }
    }
};

function updateRoute(route) {
    if (firstRoute) {
        history.pushState(route, null, route);
    }
    else {
        history.replaceState(route, null, route);
        firstRoute = route;
    }
}

function showPage(page) {
    for (var r in routes) {
        if (r == page) {
            routes[r].page.style.display = 'block';
        }
        else {
            routes[r].page.style.display = 'none';
        }
    }
}

window.onpopstate = function(evt) {
    showPage(evt.state);
    if (routes[evt.state].run) {
        routes[evt.state].run();
    }
};

window.onload = function() {
    if (window.location.pathname in routes) {
        routes[window.location.pathname].render();
    }
    else {
        routes['/404'].render();
    }
};
